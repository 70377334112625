import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { RootState } from 'app/store';
import { setCompany, setAccount, clearCompany } from '../features/companySlice';

export const useCompany = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state: RootState) => state.company.company);

  const updateCompany = (newCompanyId: string) => {
    dispatch(setCompany(newCompanyId));
  };

  const updateAccount = (newAccountId: string) => {
    dispatch(setAccount(newAccountId));
  };

  const clearCompanyData = () => {
    dispatch(clearCompany());
  };

  return { company, updateCompany, updateAccount, clearCompanyData };
};
