import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Backdrop, Snackbar, Alert, Slide, debounce, IconButton, Stack } from '@mui/material';
import { sseManager } from 'utils/useSSEEvent';
import { baseUrl } from 'api/baseQuery';
import { useUploadCampaignFileMutation } from 'api/companyApi';
import { useAppSelector, useAppDispatch } from 'hooks/useReduxHooks';
import { useHandleSuccess } from 'hooks/useHandleSuccess';
import { useHandleError } from 'hooks/useHandleError';
import { setAnalysisStatus, setAnalysisLoading, clearAnalysisLoading } from 'features/companySlice';
import { CustomButton } from 'components/ui/CustomButton';
import { CloudUpload } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

interface SSEData {
  status: "in_progress" | "completed" | "failed";
  progress: number;
}

export const FileUploadWithSSE: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const token = useAppSelector((state) => state.auth.accessToken) as string;
  const refreshToken = useAppSelector((state) => state.auth.refreshToken) as string;
  const campaignId = useAppSelector((state) => state.company.company);
  const analyseLoading = useAppSelector((state) => state.company.loadingAnalyse);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useAppDispatch();
  const [uploadFile, { isSuccess: uploadSuccess, error: fileError, reset: resetFileError }] = useUploadCampaignFileMutation();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useHandleSuccess(uploadSuccess, () => {
    setIsUploading(true);
    dispatch(setAnalysisLoading('hubspot'))
  });

  useHandleError(fileError, {
    reset: resetFileError,
    setGlobalErrors: (errors: string | null) => {
      showSnackbar(errors, 'error');
      setIsStarted(false)
      dispatch(clearAnalysisLoading())
    },
  });

  const processFile = () => {
    console.log(isStarted, "!!!")
    if (!isStarted) {
      fileInputRef.current?.click()
    }
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsStarted(true)
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      setUploadedFileName(file.name);
      setProgress(0);

      try {
        if (campaignId) {
          dispatch(setAnalysisLoading('hubspot'))
          uploadFile({ campaign_id: campaignId, file });
        }
      } catch (err: any) {
        setIsUploading(false);
        setIsStarted(false)
      }
    } catch (err: any) {
      setIsUploading(false);
      setIsStarted(false)
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const showSnackbar =  debounce((message: string | null, severity: 'success' | 'error') => {
    if (message){
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    }
  }, 500);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(null);
  };

  const hasShownSnackbarRef = useRef(false);

  useEffect(() => {
  
    const handleMessage = (data: SSEData) => {
      const { status, progress } = data;
  
      setProgress(progress);
 
      if (status === "failed") {
        showSnackbar("Analysis failed. Please try again.", "error");
        setIsUploading(false);
        setIsStarted(false)
        hasShownSnackbarRef.current = false;
      } else if (status === "completed") {
        hasShownSnackbarRef.current = true;
        dispatch(setAnalysisStatus('completed'))
        if (hasShownSnackbarRef.current) {
          showSnackbar("Analysis completed", "success");
          hasShownSnackbarRef.current = false;
        }
          setIsUploading(false);
          setIsStarted(false)
          setProgress(0);
          setUploadedFileName(null);
          hasShownSnackbarRef.current = false;
      }
    };

    if (!sseManager.isConnected && isUploading){
      const queryParams = new URLSearchParams();
      queryParams.append('account_ids', campaignId);
      sseManager.connect({
        url: `${baseUrl}/accounts/sse-status?${queryParams.toString()}`,
        token,
        refreshToken,
        onMessage: handleMessage,
        onError: () => {
          setIsUploading(false);
          hasShownSnackbarRef.current = false;
        },
        dispatch: dispatch,
      });
    }

    return () => {
      sseManager.close();
    };
  }, [isUploading, token]);

  useEffect(() => {
    if (!isUploading) {
      setProgress(0);
      dispatch(clearAnalysisLoading())
    }
  }, [isUploading]);

  const SlideTransition = (props: any) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <>
      <Box sx={{ cursor: analyseLoading === 'import' ? "not-allowed" : 'default'}}>
        <CustomButton onClick={processFile} 
                    customSize="l"  
                    type="submit"
                    customColor="#1C3C6C"
                    disabled={analyseLoading === 'import' }
                    >
          {!isUploading ? 
          ( <> <CloudUpload fontSize='small'/> Upload external data</> ) : (
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2
                }}
              >
                <CircularProgress size={20} sx={{ color: '#FFFFFF'  }} />
                <Typography variant="button" sx={{ fontWeight: 500}}>
                    Analysing: {`${progress}%`}
                </Typography>
            </Box>
          )
          }

        </CustomButton>
      </Box>
      
         
      <input type="file" hidden ref={fileInputRef} onChange={handleFileSelect} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition} 
        anchorOrigin={{
          vertical: "top",
          horizontal: "right", 
        }}
        sx={{
          '&.MuiSnackbar-root': { 
            top: '90px', 
            right: '5.5%',   
            width: "551px",
            minHeight: "72px", },
      
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor:
              snackbarSeverity === "success" ? "#27AE6033" : "#f1d2d0",
            
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderLeft: `4px solid ${
              snackbarSeverity === "success" ? "#27AE60" : "#D32F2F"
            }`,
            color: "#333",
          }}
          icon={
            snackbarSeverity === "success" ? (
              <CheckCircleOutlineIcon fontSize='large' sx={{ color: "#27AE60" }} />
            ) : (
              <ErrorOutlineIcon fontSize='large' sx={{ color: "#D32F2F" }} />
            )
          }
          action={
            <Box sx={{pr: 1}}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" sx={{ color: "#1F2932", }} />
              </IconButton>
                        
            </Box>
          }
        > 
          <Stack  flexDirection="column" >
            <Typography variant='subtitle1' color='#1F2932' fontWeight={500}>
              {snackbarSeverity === "success" ? "Data enriched successfuly" : "Analysis failed"}
            </Typography>
            <Typography variant='body1' color='#1F2932'>
              {snackbarMessage}
            </Typography>
          </Stack>
          
        </Alert>
    </Snackbar>
    </>
  );
};
