import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyState } from '../types/companyTypes';

const initialState: CompanyState = {
  account_id: localStorage.getItem('accountId') || '',
  company: localStorage.getItem('campaignId') || '',
  analysisStatus: 'idle',
  loadingAnalyse: ''
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<string>) => {
      state.company = action.payload;
      localStorage.setItem('campaignId', action.payload);
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.account_id = action.payload;
      localStorage.setItem('accountId', action.payload);
    },
    clearCompany: (state) => {
      state.company = '';
      localStorage.removeItem('campaignId');
      localStorage.removeItem('accountId')
    },
    setAnalysisStatus: (state, action: PayloadAction<string>) => {
      state.analysisStatus = action.payload;
    },
    clearAnalysisStatus: (state) => {
      state.analysisStatus = 'idle';
    },
    setAnalysisLoading: (state, action: PayloadAction<string>) => {
      state.loadingAnalyse = action.payload;
    },
    clearAnalysisLoading: (state) => {
      state.loadingAnalyse = '';
    },
  },
});

export const { setCompany, setAccount, clearCompany, setAnalysisStatus, clearAnalysisStatus, setAnalysisLoading, clearAnalysisLoading } = companySlice.actions;
export default companySlice.reducer;
