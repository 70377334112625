import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Box,
  Typography,
  Checkbox
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { CampaignMember } from 'types/companyTypes';

import { tableSortLabelClasses } from "@mui/material/TableSortLabel";
import { StarFourPoints } from 'components/images/StarImg';
import { isTextEllipsed } from 'utils/general_utils';
import { EllipsisTooltip } from './CustomTooltip';
import { DataPlusLine } from 'components/images/DataPlusImg';
import { DataPersonImg } from 'components/images/DataPersonImg';
import { BigDataOutlined } from 'components/images/DataBigImg';
import { Column } from 'types/genereal';


interface SelectionChanges {
  committee: { include: string[]; exclude: string[] };
  cluster: { include: string[]; exclude: string[] };
}

interface DataTableProps {
  columns: Column[];
  rows: CampaignMember[];
  totalRows: number;
  page: number;
  rowsPerPage: number;
  sortBy: string;
  order: 'asc' | 'desc';
  onQueryChange: (query: { page: number; per_page: number; sort: string; order?: 'asc' | 'desc' }) => void;
  isEditMode?: boolean;
  onSelectionChange?: (changes: SelectionChanges) => void;
}

export const DataTable: React.FC<DataTableProps> = ({ 
  columns,
  rows,
  totalRows,
  page,
  rowsPerPage,
  sortBy,
  order,
  onQueryChange,
  isEditMode = false,
  onSelectionChange
}) => {
  
  const [showTableTooltip, setShowTableTooltip] = useState(false);
  const [committeeChanges, setCommitteeChanges] = useState<Set<string>>(new Set());
  const [clusterChanges, setClusterChanges] = useState<Set<string>>(new Set());
  const [originalCommittee, setOriginalCommittee] = useState<Set<string>>(new Set());
  const [originalCluster, setOriginalCluster] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (isEditMode) {
      setOriginalCommittee(new Set(rows.filter(r => r.highlight_committee).map(r => r.id)));
      setOriginalCluster(new Set(rows.filter(r => r.highlight_cluster).map(r => r.id)));
      setCommitteeChanges(new Set());
      setClusterChanges(new Set());
    } else {
      setCommitteeChanges(new Set());
      setClusterChanges(new Set());
      setOriginalCommittee(new Set());
      setOriginalCluster(new Set());
    }
  }, [isEditMode, rows]);


  
  const handleSelectionChange = (rowId: string, type: 'committee' | 'cluster') => {
    const changes = type === 'committee' ? committeeChanges : clusterChanges;
    const setChanges = type === 'committee' ? setCommitteeChanges : setClusterChanges;
    const originalSet = type === 'committee' ? originalCommittee : originalCluster;

    const newChanges = new Set(changes);

    if (originalSet.has(rowId)) {
      if (changes.has(rowId)) {
        newChanges.delete(rowId);
      } else {
        newChanges.add(rowId);
      }
    } else {
      if (changes.has(rowId)) {
        newChanges.delete(rowId);
      } else {
        newChanges.add(rowId);
      }
    }

    console.log(newChanges, "?@!@")
    setChanges(newChanges);
    console.log(committeeChanges, clusterChanges)

    const getSelectionChanges = (original: Set<string>, changed: Set<string>) => {
      const include = [...changed].filter(id => !original.has(id));
      const exclude = [...original].filter(id => changed.has(id));
      return { include, exclude };
    };

    if (onSelectionChange) {
      
      console.log( originalCommittee, getSelectionChanges(originalCommittee, new Set([...committeeChanges])), "!!!", committeeChanges)
      console.log( originalCluster, getSelectionChanges(originalCluster, new Set([...clusterChanges])), "???", clusterChanges)
       onSelectionChange({
        committee: getSelectionChanges(originalCommittee, type === 'committee' ? newChanges : committeeChanges),
        cluster: getSelectionChanges(originalCluster, type === 'cluster' ? newChanges : clusterChanges)
      });
    }
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    setShowTableTooltip(isTextEllipsed(target));
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    onQueryChange({ page: newPage, per_page: rowsPerPage, sort: sortBy, order });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onQueryChange({ page: 0, per_page: newRowsPerPage, sort: sortBy, order });
  };

  const handleSort = (columnId: string) => {
    const isAsc = sortBy === columnId && order === 'desc';
    const newOrder = isAsc ? 'asc' : 'desc';
    onQueryChange({ page, per_page: rowsPerPage, sort: columnId, order: newOrder });
  };

  const renderIcon = (row: CampaignMember, column: Column) => {
    const isDataSource = column.id === 'data_source';
    const isCommittee = column.id === 'committee_probability';
    const isCluster = column.id === 'cluster_probability';
    const isEngagement = column.id === 'engagement_score';
    
    if (isEditMode && (isCommittee || isCluster)) {
      const type = isCommittee ? 'committee' : 'cluster';
      const changes = type === 'committee' ? committeeChanges : clusterChanges;
      const originalSet = type === 'committee' ? originalCommittee : originalCluster;
      
      const isSelected = changes.has(row.id) 
          ? !originalSet.has(row.id) 
          : originalSet.has(row.id); 


      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: 1, alignItems: 'center' }}>
          <Checkbox
            checked={isSelected}
            onChange={() => handleSelectionChange(row.id, type)}
            color="primary"
            checkedIcon={<StarFourPoints/>}
          />
          {
            //@ts-ignore
            row[column.id]
          }
        </Box>
      );
    }

    if (isDataSource) {
      const iconMap: Record<string, JSX.Element> = {
        'Imported data': <DataPersonImg />,
        'Enriched data': <DataPlusLine />,
        'External data': <BigDataOutlined />,
      };
      return iconMap[row.data_source] || <DataPlusLine />;
    }

    const shouldHighlight = (isCommittee && row.highlight_committee) || (isCluster && row.highlight_cluster);
    const isRightAligned = isCommittee || isCluster || isEngagement;
  
    if (isRightAligned) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'end', paddingRight: 1, alignItems: 'center' }}>
          
          {shouldHighlight && !isEditMode && 
          <Tooltip 
            title={'High membership probability within expected size'}
            placement="bottom" 
            arrow 
            sx={{
              background: '#596177E5',
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400
            }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <StarFourPoints style={{marginRight: 8}} />
            </span>
          </Tooltip>}
          
          {//@ts-ignore
          row[column.id]
          }
        </Box>
      );
    }
    //@ts-ignore
    return row[column.id];
  };

  return (
    <Box>
      {rows.length <= 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="170px"
          sx={{ borderRadius: 4 }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#697184",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            No data available
          </Typography>
        </Box>
      ) : (
        <Box>
          <TableContainer>
            <Table sx={{
              border: 'none',
              background: 'white',
              boxShadow: 0,
              tableLayout: 'fixed',
            }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    const isSorted = sortBy === column.id;
                    //@ts-ignore
                    const IconComponent: SvgIconComponent | undefined = 
                      isSorted && order === 'asc'
                        ? column.iconAsc
                        : column.label === 'Source'
                          ? column.iconDesc
                          : column.iconAsc;

                    return (
                      <TableCell
                        key={column.id}
                        sx={{
                          width: column.minWidth,
                          padding: "16px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "26px",
                          letterSpacing: "0.25px",
                          color: "#1F2932",
                          textAlign: "left",
                        }}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={isSorted}
                            direction={isSorted ? order : "desc"}
                            onClick={() => handleSort(column.id)}
                            hideSortIcon={!IconComponent}
                            IconComponent={IconComponent || (() => null)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1px",
                              width: "100%",
                              [`.${tableSortLabelClasses.icon}`]: {
                                opacity: isSorted ? 1 : 0.5,
                                transition: "opacity 0.3s ease",
                                flexShrink: 0,
                                marginLeft: 1
                              },
                            }}
                          >
                            <EllipsisTooltip>{column.label}</EllipsisTooltip>
                          </TableSortLabel>
                        ) : (
                          <EllipsisTooltip>{column.label}</EllipsisTooltip>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    style={{
                      backgroundColor:
                        row.data_source === 'Imported data' ? '#00000000' : '#f4f5f9',
                    }}
                    sx={{
                      borderBottom: "1px solid #0000001F"
                    }}
                  >
                    {columns.map((column, cellIndex) => (
                      <TableCell 
                        key={cellIndex}
                        style={
                          column.id === 'data_source'
                            ? { textAlign: 'center', verticalAlign: 'middle' }
                            : {}
                        }
                        sx={{
                          width: column.minWidth,
                          height: "56px",
                          paddingTop: 0,
                          paddingBottom: 0,
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "0.3px",
                          fontWeight: row.highlight_committee ? 550 : 400,
                          color: "#1F2932",
                        }}
                      >
                        <Tooltip 
                          //@ts-ignore
                          title={showTableTooltip || column.id === 'data_source' ? row[column.id]?.toString() || '' : ''}
                          placement="bottom"
                          arrow
                          sx={{
                            background: '#596177E5',
                            fontSize: "12px",
                            lineHeight: "16px",
                            fontWeight: 400
                          }}
                        >
                          <span
                            onMouseEnter={handleMouseEnter}
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block',
                            }}
                          >
                            {renderIcon(row, column)}
                          </span>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};