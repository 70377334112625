import React, { useState }  from 'react';
import { TextField, Box, CircularProgress, InputAdornment, useTheme, IconButton, Typography, Stack, MenuItem, Link } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { SignupFormData } from 'types/authTypes';
import { CustomButton } from 'components/ui/CustomButton';

type FieldErrors = Record<string, string>;
type GlobalError = string | null;

const industryOptions = [
    { value: 'it', label: 'Information Technology' },
    { value: 'security', label: 'Computer & Network Security' },
    { value: 'consulting', label: 'Consulting Manufacturing' },
    { value: 'telecom', label: 'Telecommunication' },
    { value: 'engineering', label: 'Engineering Financial Services' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'research', label: 'Research' },
    { value: 'retail', label: 'Retail' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'other', label: 'Other' },
];

interface SignupFormProps {
  onSubmit: (data: SignupFormData) => void;
  loading: boolean;
  fieldErrors: FieldErrors;
  globalError: GlobalError;
  setFieldErrors: React.Dispatch<React.SetStateAction<FieldErrors>>;
  setGlobalErrors: React.Dispatch<React.SetStateAction<GlobalError>>;
}

export const SignupForm: React.FC<SignupFormProps> = ({ 
  onSubmit, 
  loading, 
  fieldErrors, 
  globalError, 
  setFieldErrors,
  setGlobalErrors 
}) => {
  const theme = useTheme();  
  const { handleSubmit, control,  formState: { errors }, clearErrors } = useForm<SignupFormData>();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleFieldChange = (fieldName: any) => {
    setFieldErrors({});
    setGlobalErrors(null);
    clearErrors(fieldName);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)} 
      sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    > 
       <Stack gap={0.5} pb="42px">
          <Typography textAlign="left"  sx={{fontWeight: 500, fontSize:"18px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
            Personal data
          </Typography>
          <Typography variant="body1" color="#4C545B" textAlign="left">
            Please provide your personal information to create your account
          </Typography>
      </Stack>
      <Box gap="42px" paddingBottom="24px" display="flex" flexDirection="column">
        <Controller
          name="first_name"
          control={control}
          defaultValue=""
          rules={{ 
            required: 'First name is required',
            minLength: {
              value: 1,
              message: 'First name must be at least 1 character'
            },
            maxLength: {
              value: 50,
              message: 'First name must not exceed 50 characters'
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="First name"
              placeholder="First name"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off',
                },
              }}
              onChange={(e) => {
                field.onChange(e);
                handleFieldChange('first_name');
              }}
              error={!!errors.first_name || !!fieldErrors.first_name} 
              helperText={errors.first_name?.message || fieldErrors.first_name}
              fullWidth
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          defaultValue=""
          rules={{ 
            required: 'Last name is required',
            minLength: {
              value: 1,
              message: 'Last name must be at least 1 character'
            },
            maxLength: {
              value: 50,
              message: 'Last name must not exceed 50 characters'
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Last name"
              placeholder="Last name"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off',
                },
              }}
              onChange={(e) => {
                field.onChange(e);
                handleFieldChange('last_name');
              }}
              error={!!errors.last_name || !!fieldErrors.last_name} 
              helperText={errors.last_name?.message || fieldErrors.last_name}
              fullWidth
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ 
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              placeholder="example@gmail.com"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off',
                },
              }}
              onChange={(e) => {
                field.onChange(e);
                handleFieldChange('email');
              }}
              error={!!errors.email || !!fieldErrors.email} 
              helperText={errors.email?.message || fieldErrors.email}
              fullWidth
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ 
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters'
            },
            maxLength: {
              value: 32,
              message: 'Password must not exceed 32 characters'
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              placeholder='Password'
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                field.onChange(e);
                handleFieldChange('password');
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password || !!fieldErrors.password}
              helperText={errors.password?.message || fieldErrors.password}
              fullWidth
            />
          )}
        />
      </Box>
      <Stack gap={0.5} pb="42px">
          <Typography  textAlign="left"  sx={{fontWeight: 500, fontSize:"18px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
          Company information
          </Typography>
          <Typography variant="body1" color="#4C545B" textAlign="left">
          Please provide information about your company to help us better understand your occupation
          </Typography>
      </Stack>
      <Box gap="42px" paddingBottom="32px" display="flex" flexDirection="column">
        <Controller
            name="company_name"
            control={control}
            defaultValue=""
            rules={{ 
              required: 'Company name is required',
              minLength: {
                value: 1,
                message: 'Company name must be at least 1 character'
              },
              maxLength: {
                value: 50,
                message: 'Company name must not exceed 50 characters'
              }
            }}
            render={({ field }) => (
                <TextField
                {...field}
                label="Company Name"
                placeholder="Company Name"
                inputProps={{
                    autoComplete: 'off',
                    form: {
                    autoComplete: 'off',
                    },
                }}
                onChange={(e) => {
                    field.onChange(e);
                    handleFieldChange('company_name');
                }}
                error={!!errors.company_name || !!fieldErrors.company_name}
                helperText={errors.company_name?.message || fieldErrors.company_name}
                fullWidth
                />
            )}
        />
        <Controller
            name="industry"
            control={control}
            defaultValue=""
            rules={{ required: 'Industry is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={field.value ? "Industry" : ""}
                placeholder="Select your industry"
                onChange={(e) => {
                  field.onChange(e);
                  handleFieldChange('industry');
                }}
                error={!!errors.industry || !!fieldErrors.industry}
                helperText={errors.industry?.message || fieldErrors.industry}
                fullWidth
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: unknown) => {
                    return String(value) || 'Select Industry';
                  },
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root': {
                          padding: '8px 16px',
                          '&:hover': {
                            backgroundColor: '#a8c0cc'  
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#FE8836', 
                            '&:hover': {
                              backgroundColor: '#FE8836DD' 
                            }
                          }
                        }
                      }
                    }
                  }
                }}
                sx={{
                  textAlign: 'left',
                  '& .MuiSelect-select': {
                    paddingTop: '8px',
                    paddingBottom: '8px',
                  },
                  '& .MuiSelect-icon': {
                    color: '#1F2932',
                  }
                }}
              >
                {industryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
      </Box>
      
      <CustomButton
        type="submit"
        variant="contained"
        fullWidth
        customColor={theme.palette.primary.main} 
        customGradientColor={theme.palette.primary.dark}
        disabled={loading}
      >
        {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Sign up'}
      </CustomButton>

      <Typography 
        variant="button" 
        color="#4C545B" 
        textAlign="left"
        fontWeight={400}
        pt={1}
      >
        Already have an account?  
        <a href="/login" style={{ fontWeight: 500, textDecoration: 'none', color: '#000000DE', paddingLeft: '4px' }}>
          Sign in now
        </a>
      </Typography>

      <Box minHeight={"55px"} >
        {globalError && <Typography color="error" mt={1}> {globalError}</Typography>}
      </Box>
    </Box>
  );
};