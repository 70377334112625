import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';

type FieldErrorMap = Record<string, string>;

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError
): { fieldErrors: FieldErrorMap; globalError: string | null } => {
  const fieldErrors: FieldErrorMap = {};
  let globalError: string | null = null;
 
  if (error && 'status' in error) {
    console.log(error, "!!!!", error.data && typeof error.data === 'object' && 'detail' in error.data)
    if (error.data && typeof error.data === 'object' && 'detail' in error.data) {
      const detail = error.data.detail;
      console.log(detail)
      if (Array.isArray(detail)) {
        detail.forEach((err: any) => {
          if (err.loc && err.loc.length > 1 && typeof err.loc[1] === 'string') {
            const fieldName = err.loc[1];
            fieldErrors[fieldName] = err.msg || 'Invalid value';
          } else if (err.msg) {
            globalError = err.msg;
          }
        });
      } else if (typeof detail === 'string') {
        globalError = detail;
      }
    } 
    else if (error.data && typeof error.data === 'object') {
      globalError = (error.data as { message?: string }).message || 'Bad request';
    }
  } else {
    globalError = 'An unexpected error occurred'
  }

  return { fieldErrors, globalError };
 
}