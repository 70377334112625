import React, { useState, useRef, useEffect } from 'react';
import { TextField, Typography, Box, Stack, CircularProgress, FormHelperText, Divider } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { DeleteImg } from 'components/images/deleteImg';
import { SwitchImg } from 'components/images/SwitchImg';
import { BaseModal } from './BaseModal';
import { CustomButton } from './CustomButton';
import { HubspotImg } from 'components/images/Hubspot';
import { SalesforceImg } from 'components/images/Salesforce';
import { setAnalysisLoading, clearAnalysisLoading } from 'features/companySlice';
import { useCreateCampaignMutation, useUploadCampaignFileMutation,
  useCreateHubspotImportMutation, useGetHubspotUrlMutation, useCreateHubspotUrlMutation,
  useCreateSalesforceImportMutation,
  useGetSalesforceUrlMutation,
  useCreateSalesforceUrlMutation,
  useGenerateCampaignMutation
 } from '../../api/companyApi'
import { useCompany } from '../../hooks/useCompanyHook';
import { useHandleError } from 'hooks/useHandleError';
import { useHandleSuccess } from 'hooks/useHandleSuccess';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { RootState } from 'app/store';
import { selectIsInit } from 'features/authSlice';
import { sseManager } from 'utils/useSSEEvent';
import { baseUrl } from 'api/baseQuery';
import { AnnotationImg } from 'components/images/AnnotatioImg';
import { MingcutePdfLine } from 'components/images/PdfFileImg';

interface NewCampaignUploadModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  isCampaignExist: boolean; 
}
type FieldErrorMap = Record<string, string | undefined>;

export const NewCampaignUploadModal: React.FC<NewCampaignUploadModalProps> = ({ open, onClose, onComplete, isCampaignExist }) => {
  const dispatch = useAppDispatch();
  const { updateCompany, updateAccount } = useCompany();
  
  const [formData, setFormData] = useState({
    company_name: '',
    product_abstract: '',
    target_audience: '',
    product_name: '',
  });
  const [newAccountid, setNewAccountid] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(1);
  const token = useAppSelector((state: RootState) => state.auth.accessToken);
  const refreshToken = useAppSelector((state: RootState) => state.auth.refreshToken);
  const showInitModal = useAppSelector(selectIsInit);
  const campaignId = useAppSelector((state) => state.company.company);
  const isRegistrationComplete = useAppSelector(state => state.auth.isRegistrationModalOpen);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldErrorMap>({});
  const [globalErrors, setGlobalErrors] = useState<string | null>(null);
  const [hubspotErrors, setHubspotErrors] = useState<string | null>(null);
  const [salesforceErrors, setSalesforceErrors] = useState<string | null>(null);
  const [isClosedByComplete, setIsClosedByComplete] = useState(false);
  const [newCampaignid, setNewCamaignid] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productUrl, setProductUrl] = useState('');
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analyzeProgress, setAnalyzeProgress] = useState(0);
  const [showProductForm, setShowProductForm] = useState(false);
  const [modalWidth, setModalWith] = useState(520);
  const [lastAnalysis, setLastAnalysis] = useState({
    url: '',
    fileName: ''
   });
   
  
  const [generateCampaign, { isSuccess: isGeneretedCreated, error: generateError, reset: resetGenerateError, data: generateData }] = useGenerateCampaignMutation();
  
  const [createCampaign, { isSuccess: isCampaignCreated, data: createCampaignResult, isLoading: creatingCampaign, error: campaignError, reset: resetCampaignError }] = useCreateCampaignMutation();
  const [uploadFile, { isSuccess: uploadSuccess, isLoading: uploadingFile, error: fileError, reset: resetFileError, data: uploadCampaignFileData  }] = useUploadCampaignFileMutation();
  
  const [createHubspotImport, { isLoading: isImporting }] = useCreateHubspotImportMutation();
  const [getHubspotUrl, { isSuccess: urlSuccess, error: urlError, reset: resetUrlError, data: urlHubspotData }] = useGetHubspotUrlMutation();
  const [createHubspotUrl, { isSuccess: hubspotSuccess, isLoading: isConnecting, error: hubspotErrorData, reset: resetHubspotError }] = useCreateHubspotUrlMutation();

  const [createSalesforceImport, { isLoading: isImportingSalesforce }] = useCreateSalesforceImportMutation();
  const [getSalesforceUrl, { isSuccess: urlSuccessSalesforce, error: urlErrorSalesforce, reset: resetUrlErrorSalesforce, data: urlSalesforceData }] = useGetSalesforceUrlMutation();
  const [createSalesforceUrl, { isSuccess: salesforceSuccess, isLoading: isConnectingSalesforce, error: salesforceErrorData, reset: resetSalesforceError }] = useCreateSalesforceUrlMutation();

  useHandleError(salesforceErrorData, {
    setGlobalErrors: setSalesforceErrors,
    reset: resetSalesforceError,
  });

  useHandleError(urlErrorSalesforce, {
    setGlobalErrors: setSalesforceErrors,
    reset: resetUrlErrorSalesforce,
  });

  useHandleSuccess(salesforceSuccess, () => {
    dispatch(setAnalysisLoading('import'))
    clearQueryParams();
    if (newCampaignid) {
      handleSalesforceIntegration();
    } else {
      setSalesforceErrors("Campaign not found, try again")
    }
  });

  useHandleSuccess(urlSuccessSalesforce, () => {
    if (!urlSalesforceData) return;
    window.location.href = urlSalesforceData.url;
  });


  useHandleSuccess(isGeneretedCreated, () => {
    if (!generateData) return;
    setFormData({
      company_name: generateData.company_name || '',
      product_abstract: generateData.product_abstract || '',
      target_audience: generateData.target_audience || '',
      product_name: generateData.product_name || ''
    });
    setModalWith(629);
    setShowProductForm(true);
  });


  
  const handleAnalyze = async () => {
    try {
      setIsAnalyzing(true);
      setGlobalErrors(null);
  
      const needsAnalysis = !formData.product_name || 
        lastAnalysis.url !== productUrl ||
        lastAnalysis.fileName !== pdfFile?.name;
  
      if (needsAnalysis) {
        const analyzeData: any = {};
        if (productUrl) analyzeData.url = productUrl;
        if (pdfFile) analyzeData.pdf_file = pdfFile;
        generateCampaign(analyzeData);
        setLastAnalysis({
          url: productUrl,
          fileName: pdfFile?.name || ''
        });
      } else {
        setModalWith(629);
        setShowProductForm(true); 
        setIsAnalyzing(false);
      }
        
     
    } catch (error) {
      console.error('Error starting analysis:', error);
      setGlobalErrors('Failed to start analysis. Please try again.');
      setIsAnalyzing(false);
    }
  };

  useHandleError(generateError, {
    reset: resetGenerateError,
    setGlobalErrors: (errors: string | null) => {
      console.log("erros", errors)
      setGlobalErrors(errors)
      setIsAnalyzing(false);
    },
  });
  

  const handleSalesforceIntegration = () => {
    setGlobalErrors(null);
    setSalesforceErrors(null);
    localStorage.setItem('formData', JSON.stringify(formData));
    createSalesforceImport({ campaign_id: newCampaignid })
      .unwrap()
      .then(() => {
        setIsFileUploaded(true);
        dispatch(setAnalysisLoading('import'))
        listenToSalesforceSSE(newCampaignid);
      })
      .catch((error) => {
        console.error(error)
        if (error?.status === 400) {
          getSalesforceUrl({ campaign_id: newCampaignid })
        } else if(error?.status === 404) {
          setSalesforceErrors("Campaign not found")
        } else if(error?.status === 405) {
          createCampaign(formData)
            .unwrap()
            .then((campaignResponse) => {
              setNewCamaignid(campaignResponse.campaign_id);
              handleSalesforceIntegration();
            })
            .catch((creationError) => {
              console.error('Company creation error:', creationError);
              setSalesforceErrors("Analysis error, try again later");
            });
        } else if(error?.status === 428) {
          setSalesforceErrors("CRM does not contain any relevant data")
        } else {
          setSalesforceErrors("Analysis error from Salesforce, try again later")
        }
      });
  };


  const listenToSalesforceSSE = (campaign: string) => {
    if (!token) {
      return;
    }
    const queryParams = new URLSearchParams();
    queryParams.append('account_ids', newAccountid);
    sseManager.connect({
      url: `${baseUrl}/accounts/sse-status?${queryParams.toString()}`,
      token,
      refreshToken,
      onMessage: (data) => {
        const { status, progress } = data[0];
        setProgress(progress);

        if (status === 'failed') {
          setSalesforceErrors('Something went wrong during the analysis. Please try again.');
          setProgress(0);
          setIsFileUploaded(false);
          setUploadedFile(null);
        }

        if (status === 'completed') {
          setTimeout(() => {
            handleCompleteAndClose();
          }, 500);
        }
      },
      onError: (error) => {
        console.error('SSE Error:', error);
      },
      dispatch,
    });

    return () => {
      sseManager.close(); 
    };
  };


  useHandleError(hubspotErrorData, {
    setGlobalErrors: setHubspotErrors,
    reset: resetHubspotError,
  });

  useHandleError(urlError, {
    setGlobalErrors: setHubspotErrors,
    reset: resetUrlError,
  });

  useHandleSuccess(hubspotSuccess, () => {
    dispatch(setAnalysisLoading('import'))
    clearQueryParams();
    if (newCampaignid) {
      handleHubspotIntegration();
    } else {
      setHubspotErrors("Campaign not found, try again")
    }
  });

  useHandleSuccess(urlSuccess, () => {
    if (!urlHubspotData) return;
    window.location.href = urlHubspotData.url;
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    const code = urlParams.get('code');
    setNewCamaignid(urlParams.get('state') || '');
    if (type === 'hubspot' && code && !isModalOpen) {
      const savedData = localStorage.getItem('formData');
      if (savedData) {
          const parsedData = JSON.parse(savedData);
          setFormData(parsedData);
      }
      setModalOpen(true);
      setCurrentStep(2);
      createHubspotUrl({ code });
    } else if (type === 'salesforce' && code && !isModalOpen) {
      const savedData = localStorage.getItem('formData');
      if (savedData) {
          const parsedData = JSON.parse(savedData);
          setFormData(parsedData);
      }
      setModalOpen(true);
      setCurrentStep(2);
      createSalesforceUrl({ code });
    }
  }, []);


  const clearQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState(null, '', url.toString());
  }

  const handleHubspotIntegration = () => {
    setGlobalErrors(null);
    setHubspotErrors(null);
    localStorage.setItem('formData', JSON.stringify(formData));
    createHubspotImport({ campaign_id: newCampaignid })
      .unwrap()
      .then(() => {
        setIsFileUploaded(true);
        dispatch(setAnalysisLoading('import'))
        listenToHubspotSSE(newCampaignid);
      })
      .catch((error) => {
        console.error(error)
        if (error?.status === 400) {
          getHubspotUrl({ campaign_id: newCampaignid })
        } else if(error?.status === 404) {
          setHubspotErrors("Campaign not found")
        } else if(error?.status === 405) {
          createCampaign(formData)
            .unwrap()
            .then((campaignResponse) => {
              setNewCamaignid(campaignResponse.campaign_id);
              handleHubspotIntegration();
            })
            .catch((creationError) => {
              console.error('Company creation error:', creationError);
              setHubspotErrors("Analysis error, try again later");
            });
        } else if(error?.status === 428) {
          setHubspotErrors("CRM does not contain any relevant data")
        } else {
          setHubspotErrors("Analysis error from hubspot, try again later")
        }
      });
  };

  const listenToHubspotSSE = (campaign: string) => {
    if (!token) {
      return;
    }
    
    const queryParams = new URLSearchParams();
    queryParams.append('account_ids', newAccountid);
    sseManager.connect({
      url: `${baseUrl}/accounts/sse-status?${queryParams.toString()}`,
      token,
      refreshToken,
      onMessage: (data) => {
        const { status, progress } = data[0];
        setProgress(progress);

        if (status === 'failed') {
          setHubspotErrors('Something went wrong during the analysis. Please try again.');
          setProgress(0);
          setIsFileUploaded(false);
          setUploadedFile(null);
        }

        if (status === 'completed') {
          setTimeout(() => {
            handleCompleteAndClose();
          }, 500);
        }
      },
      onError: (error) => {
        console.error('SSE Error:', error);
      },
      dispatch,
    });

    return () => {
      sseManager.close(); 
    };
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFieldErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    if (event.target.files && event.target.files.length > 0 && file) {
        setUploadedFile(file.name);
        uploadFile({ campaign_id: newCampaignid, file })
    }
  };

  const validateAndOpenFileDialog = () => {
    setError(null);
    fileInputRef.current?.click();
  };


  const validateStepOne = () => {
    const { company_name, product_abstract, target_audience, product_name } = formData;
    const newFieldErrors: FieldErrorMap = {};
    if (!company_name) newFieldErrors.company_name = 'Company name is required';
    if (!product_abstract) newFieldErrors.product_abstract = 'Product abstract is required';
    if (!target_audience) newFieldErrors.target_audience = 'Target audience is required';
    if (!product_name) newFieldErrors.product_name = 'Product name is required';

    setError(null);
    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      return;
    }
    createCampaign(formData);
  };

  useHandleError(campaignError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetCampaignError,
  });
  useHandleError(fileError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetFileError,
  });

  useHandleSuccess(isCampaignCreated, () => {
    if (createCampaignResult?.campaign_id) {
      setNewCamaignid(createCampaignResult.campaign_id);
      setModalWith(467);
      setCurrentStep(2);
      setShowProductForm(false);
    }
  });

  const handleCancel = () => {
    onClose();
    setCurrentStep(1);
  };

  const handleBack = () => {
    if (showProductForm) {
      setShowProductForm(false);
      setModalWith(520);
      setIsAnalyzing(false); 
    } else {
      setCurrentStep(1);
      setModalWith(629);
      setShowProductForm(true)
    }
  };

  useHandleSuccess(uploadSuccess, () => {
    if (!uploadCampaignFileData) return;
    setNewAccountid( uploadCampaignFileData.account_id || '');
    setIsFileUploaded(true);
    dispatch(setAnalysisLoading('import'))
  });


  useEffect(() => {
    if (isClosedByComplete) {
      setFormData({
        company_name: '',
        product_abstract: '',
        target_audience: '',
        product_name: ''
      });
      setUploadedFile(null);
      setIsFileUploaded(false);
      setProgress(0);
      setGlobalErrors(null);
      setHubspotErrors(null);
      setSalesforceErrors(null);
      setFieldErrors({});
      setIsClosedByComplete(false);
      setCurrentStep(1);
    }
  }, [isClosedByComplete]);

  const handleCompleteAndClose = () => {
    dispatch(clearAnalysisLoading())
    updateCompany(newCampaignid);
    updateAccount(newAccountid);
    setIsClosedByComplete(true);
    onComplete();
    onClose();
  };

  useEffect(() => {
    if (!newCampaignid || !isFileUploaded || !token) {
      return;
    }

    const handleMessage = (data: any) => {
     
      const { account_id, status, progress } = data[0];
      console.log(data, '!!!!!', account_id)
      setProgress(progress);
  
      if (status === 'failed') {
        setGlobalErrors("Something went wrong during the analysis. Please try to upload data again");
        setIsFileUploaded(false);
        setUploadedFile(null);
        setProgress(0);
      }
  
      if (status === 'completed') {
        setTimeout(() => {
          handleCompleteAndClose();
        }, 500);
      }
    };

    const handleError = (error: any) => {
      console.error('SSE Error:', error);
    };

    const queryParams = new URLSearchParams();
    queryParams.append('account_ids', newAccountid);
    sseManager.connect({
      url: `${baseUrl}/accounts/sse-status?${queryParams.toString()}`,
      token,
      refreshToken,
      onMessage: handleMessage,
      onError: handleError,
      dispatch: dispatch
    });
    
    return () => {
      sseManager.close(); 
    };
  }, [newCampaignid, isFileUploaded, token, onClose]);
  
  useEffect(() => {
    if (isCampaignCreated || uploadSuccess) {
      setGlobalErrors(null);
      setFieldErrors({});
    }
  }, [isCampaignCreated, uploadSuccess]);


  return (
    <BaseModal
      open={open || (showInitModal && isCampaignExist && !isRegistrationComplete)}
      onClose={onClose}
      title={"New campaign"}
      width={{ xs: '90%', sm: modalWidth, md: modalWidth }}
    >
      <Stack gap={3}>
        <Typography variant="subtitle1" sx={{ color: "#4C545B" }}>
          Get highly accurate analysis results in 2 quick steps
        </Typography>
        {currentStep === 1 && !showProductForm ? (
  <Box>
    <Stack gap={1}>
      <Typography sx={{fontWeight: 500, fontSize:"18px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
        Step 1 of 2: Add product description
      </Typography>
      <Typography variant="body1" sx={{ color: "#4C545B" }}>
        Please provide a direct URL link or/and a PDF file with a specific product description
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: "custom.textDarkBlue", 
          display: 'flex', 
          alignItems: 'flex-start',
          gap: 1.1,
          mt: '8px' 
        }}
      >
        <AnnotationImg/>
        Analysis will have a high dependence on the accuracy and quality of the information
      </Typography>
    </Stack>

    {!isAnalyzing ? (
      <Stack gap={1} mt={5}>
        <TextField
          fullWidth
          label="Product description URL"
          value={productUrl}
          onChange={(e) => setProductUrl(e.target.value)}
          placeholder="www.example.com"
        />
        
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Divider sx={{ flexGrow: 1, color: '#CFCFCF' }} />
          <Typography
            variant="button"
            sx={{
              marginX: "8px",
              color: "#4C545B",
            }}
          >
            or / and
          </Typography>
          <Divider sx={{ flexGrow: 1, color: '#CFCFCF' }} />
        </Box>

        <Box>
          {pdfFile ? (
            <Box display="flex" alignItems="center" gap={1}  width='100%'  >
              <Box display="flex" alignItems="center" flexGrow={1} minWidth={0}>
                <MingcutePdfLine /> 
                <Typography color='#1D3E70' pl={1} sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}>
                  {pdfFile.name}
                </Typography>
              </Box>
              <CustomButton
                variant="outlined"
                customColor='' 
                customSize='s'
                onClick={() => fileInputRef.current?.click()}
                sx={{ 
                  minWidth: 'auto',
                  p: 1,
                  width: "40px",
                  height: "40px", 
                  border: "1px solid #1D3E70"
                }}
              >
                <SwitchImg />
              </CustomButton>
              <CustomButton
                variant="outlined"
                customColor='' 
                customSize='s'
                onClick={() => setPdfFile(null)}
                sx={{ 
                  minWidth: 'auto',
                  p: 1,
                  width: "40px",
                  height: "40px", 
                  border: "1px solid #1D3E70"
                }}
              >
                <DeleteImg />
              </CustomButton>
            </Box>
          ) : (
            
            <CustomButton
              variant="outlined"
              fullWidth
              customColor='' 

              textColor="#1D3E70" 
              onClick={() => fileInputRef.current?.click()}
              sx={{ height: "48px", border: "1px solid #1D3E70" }}
            >
              <MingcutePdfLine /> 
              Upload PDF
            </CustomButton>
          )}
          <input
            type="file"
            hidden
            ref={fileInputRef}
            accept=".pdf"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                if (file.type === 'application/pdf') {
                  setPdfFile(file);
                } else {
                  setGlobalErrors("You have chosen unsupported file extension. Please choose .pdf file and try again");
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                }
              }
            }}
          />
        </Box>
      </Stack>
    ) : (
      <Box display="flex" alignItems="center" justifyContent="center" py={3} gap={2}>

        <Typography variant="body1" color="text.secondary">
          Analyzing the product
        </Typography>
        <CircularProgress size={24} sx={{ color: 'custom.subTitleModal' }} />
      </Box>
    )}

    {globalErrors && (
      <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
        {globalErrors}
      </Typography>
    )}

    {!isAnalyzing && (
      <Stack direction="row" justifyContent="space-between" gap={1} mt={"32px"} >
        <CustomButton
          variant="contained"
          customColor="#F1F1F1"
          textColor="#4C545B"
          fullWidth
          sx={{ height: "48px" }}
          onClick={handleCancel}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          customColor="#1C3C6C"
          fullWidth
          sx={{ height: "48px" }}
          onClick={handleAnalyze}
          disabled={!productUrl && !pdfFile}
        >
          Confirm
        </CustomButton>
      </Stack>
    )}
  </Box>
) : currentStep === 1 && showProductForm ? (
        <Box>
          <Stack gap={1}>
            <Typography 
              sx={{fontWeight: 500, fontSize:"18px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
              Step 1 of 2: Add client’s company info
            </Typography>
            <Typography variant="body1" sx={{ color: "#4C545B" }}>
              Please provide a direct URL link or/and a PDF file with a specific product description
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: "custom.textDarkBlue", 
                display: 'flex', 
                alignItems: 'flex-start',
                gap: 1.1,
                mt: '8px' 
              }}
            >
              <AnnotationImg/>
              Analysis will have a high dependence on the accuracy and quality of the information
            </Typography>
          </Stack>
          <Stack gap={4} pt={5}>
          <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                name="company_name"
                placeholder=" e.g., INFUSE"
                label="Company name *"
                value={formData.company_name}
                variant="outlined"
                error={!!fieldErrors.company_name}
                helperText={fieldErrors.company_name}
                onChange={handleInputChange}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.company_name.length > 100 ? 'error.main' : '#00000099',
                }}
              >
                {`${formData.company_name.length}/100`}
              </FormHelperText>
            </Box>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                name="product_name"
                placeholder=" e.g., INFUSE"
                label="Product name *"
                value={formData.product_name}
                variant="outlined"
                error={!!fieldErrors.product_name}
                helperText={fieldErrors.product_name}
                onChange={handleInputChange}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.product_name.length > 100 ? 'error.main' : '#00000099',
                }}
              >
                {`${formData.product_name.length}/100`}
              </FormHelperText>
            </Box>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                minRows={4}
                maxRows={4}
                label="Product description *"
                variant="outlined"
                error={!!fieldErrors.product_abstract}
                helperText={fieldErrors.product_abstract}
                name="product_abstract"
                placeholder=" e.g., INFUSE is a global high-performance demand partner delivering demand strategies, programs, and outcomes for the most admired B2B brands"
                value={formData.product_abstract}
                onChange={handleInputChange}
                InputProps={{
                  rows: 4,
                  multiline: true,
                  inputComponent: 'textarea',
                  style: {
                    overflow: 'auto',
                  },
                }}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.product_abstract.length > 1000 ? 'error.main' : '#00000099', 
                }}
              >
              {`${formData.product_abstract.length}/1000`}
              </FormHelperText>
            </Box>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <TextField
                fullWidth
                name="target_audience"
                placeholder=" e.g., Demand specialists, marketers, sales, executives. Manager+ seniority. Located in NOAM, EMEA, LATAM, APAC."
                value={formData.target_audience}
                error={!!fieldErrors.target_audience}
                helperText={fieldErrors.target_audience}
                label="Product target audience *"
                variant="outlined"
                onChange={handleInputChange}
                InputProps={{
                  rows: 4,
                  multiline: true,
                  inputComponent: 'textarea',
                  style: {
                    overflow: 'auto',
                  },
                }}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  bottom: -20 ,
                  right: 0,
                  textAlign: 'right',
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: formData.target_audience.length > 300 ? 'error.main' : '#00000099',
                }}
              >
              {`${formData.target_audience.length}/300`}
              </FormHelperText>
            </Box>
          </Stack>
        </Box>
         ) : currentStep === 2 && (
          <Box>
            <Stack gap={2}>
              <Typography  sx={{fontWeight: 500, fontSize:"17px", lineHeight: "24px", color: "custom.textDarkBlue"}}>
                Step 2 of 2: Upload target companies contact lists to identify buying committee
              </Typography>
              <Typography variant="body1" sx={{ color: "#4C545B" }}>
                Add a file with contacts data related to one or several companies, or sync your CRM data
              </Typography>
            </Stack>
            <Stack pt={3}>
            </Stack>
            <Stack gap={1} sx={{ textAlign: "center" }}>
              {progress === 0 && !isFileUploaded ? (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Preferred format: <b>.csv</b> or <b>Excel file</b>
                  </Typography>
                  <CustomButton
                    variant="contained"
                    customColor="#1C3C6C"
                    fullWidth
                    type='button'
                    onClick={validateAndOpenFileDialog}
                    sx={{ height: "48px" }}
                  >
                    <UploadFileIcon fontSize='medium'/>            
                    Upload from desktop
                    
                  </CustomButton>
                </>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center" py={1} gap={2}>
                  <CircularProgress  size={20} sx={{ color: 'custom.subTitleModal' }} />
                  <Typography variant='body1' color="text.secondary">
                  {`Analysing${uploadedFile ? ` ${uploadedFile}` : ''}`}: <b>{`${progress}%`}</b>
                  </Typography>
                </Box>
              )}
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              {globalErrors && (
              <Typography color="error" sx={{ }}>
                {globalErrors}
              </Typography>
              )}
      </Stack>
      
      { !isFileUploaded && 
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            margin: "8px 0",
          }}
        >
          <Divider color='#CFCFCF' sx={{ flexGrow: 1 }} />
            <Typography
              variant='button'
              sx={{
                marginX: "8px",
                color: "#4C545B",
              }}
            >
              or
            </Typography>
          <Divider color='#CFCFCF' sx={{ flexGrow: 1 }} />
        </Box>
        <CustomButton
          variant="outlined"
          customColor="#FFFFFF"
          textColor='#1D3E70'
          fullWidth
          onClick={handleHubspotIntegration}
          type='button'
          sx={{ height: "48px", borderColor: "#1D3E70" }}
      >   
           {isImporting ? 
          (  <CircularProgress size={20} sx={{ color: 'custom.subTitleModal' }} />) : (
            <HubspotImg/>   
          )
          }
                    
          Upload from HubSpot
        </CustomButton>
        {hubspotErrors && (
              <Typography color="error" sx={{ pt:1, pb:1, textAlign:'center' }}>
                {hubspotErrors}
              </Typography>
              )}
        <CustomButton
          variant="outlined"
          customColor="#FFFFFF"
          textColor='#1D3E70'
          onClick={handleSalesforceIntegration}
          fullWidth
          type='button'
          sx={{ height: "48px", marginTop: 1, borderColor: "#1D3E70" }}
        > 
          {isImportingSalesforce ? 
          (  <CircularProgress size={20} sx={{ color: 'custom.subTitleModal' }} />) : (
            <SalesforceImg/>      
          )
          }      
          Upload from Salesforce
        </CustomButton>
        {salesforceErrors && (
              <Typography color="error" sx={{ pt:1, pb:1, textAlign:'center' }}>
                {salesforceErrors}
              </Typography>
              )}
        </Box>}
      </Box>)}
      

        {!isFileUploaded &&  currentStep === 1 && showProductForm && (
          <Stack direction="row" justifyContent="space-between" gap={1} pt={2}>
            <CustomButton onClick={handleBack}            
              variant="contained"
              textColor='#4C545B'
              fullWidth
              type='button'
              sx={{ height: "48px"}} 
              customColor="#F1F1F1">
              Back
            </CustomButton>
            <CustomButton 
              onClick={validateStepOne} 
              variant="contained"
              fullWidth
              type='button'
              customColor="#1C3C6C"
              sx={{ height: "48px"}} >
                
              Next Step
            </CustomButton>
          </Stack>
        )}
        {!isFileUploaded && currentStep === 2 && (
            <CustomButton textColor='#4C545B' onClick={handleBack} customColor='#F1F1F1' variant="contained" fullWidth   type='button' sx={{ height: "48px"}} > 
              Back
            </CustomButton>
        )}
      </Stack>
    </BaseModal>
  );
};
