import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { BaseModal } from './BaseModal';
import { CustomButton } from './CustomButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppSelector, useAppDispatch } from 'hooks/useReduxHooks';
import { setRegistrationModalOpen } from 'features/authSlice';

interface RegistrationSuccessModalProps {
  onGetStarted: () => void;
}

export const RegistrationSuccessModal: React.FC<RegistrationSuccessModalProps> = ({ 
  onGetStarted 
}) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(state => state.auth.isRegistrationModalOpen);
  const handleClose = () => {
    dispatch(setRegistrationModalOpen(false));
  };

  const handleGetStarted = () => {
    handleClose();
  };

  return (
    <BaseModal
      open={isOpen}
      onClose={handleClose}
      title="Registration successful" 
      icon={
        <CheckCircleOutlineIcon 
          sx={{ 
            color: '#00B315', 
            fontSize: '27px'
          }} 
        />
      }
      width={{ xs: "70%", sm: 431, md: 431 }}
    >
      <Typography 
            variant="subtitle1" 
            sx={{ 
              color: "text.secondary"
            }}
          >
        Your account has been successfully created
      </Typography>
      <Stack gap={3} alignItems="center" textAlign="center" pt={4}> 
        
        <Box sx={{ width: '100%' }}>
          <CustomButton
            onClick={handleGetStarted}
            variant="contained"
            customColor="#1C3C6C"
            fullWidth
            sx={{ 
              height: "48px",
              width: '100%'
            }}
          >
            Get started
          </CustomButton>
        </Box>
      </Stack>
    </BaseModal>
  );
};